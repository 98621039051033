<template>
  <div>
    <!-- <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="loading">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay> -->
    <v-card>
      <!-- <v-card-title class="grid-close">
        <span class="headline">Etiquetas de {{ category.name }}</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title> -->

      <v-card-text
        style="max-height: 65vh; min-height: 65vh; overflow-y: scroll"
      >
        <v-container fluid>
          <v-row>
            <v-col cols="12" md="7" sm="7">
              <v-text-field
                v-model="search"
                clearable
                rounded
                flat
                filled
                hide-details
                :label="`Buscar etiqueta`"
                prepend-inner-icon="fa-search"
                class="d-none d-md-flex"
              />
            </v-col>
            <v-col cols="12" md="5" sm="5">
              <v-row justify="end">
                <v-btn
                  color="primary"
                  class="ma-2 white--text mr-5"
                  @click="sortDialog = true"
                  :loading="loading"
                  :disabled="tags.length <= 1"
                >
                  <v-icon right dark class="mr-3">fas fa-bars</v-icon>
                  Ordenar
                </v-btn>

                <v-btn
                  color="primary"
                  class="ma-2 white--text"
                  @click="
                    addDialog = true;
                    selectedItem = null;
                  "
                  :loading="loading"
                >
                  <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                  Agregar etiqueta
                </v-btn>
              </v-row>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headers"
            :items="tags"
            :search="search"
            :items-per-page="5"
            fixed-header
            :loading="loading"
            :sort-by="['position']"
            class="mt-5"
            :sort-desc="[false, true]"
            item-key="id"
            :footer-props="{
              itemsPerPageOptions: [5],
            }"
          >
            <template v-slot:[`item.categoryVisible`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { categoryVisible: item.categoryVisible },
                      item['.key'],
                      item
                    )
                  "
                  v-model="item.categoryVisible"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.image`]="{ item }">
              <v-avatar v-if="item.image">
                <img :src="item.image.original" alt="John" />
              </v-avatar>
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <div style="display: flex ; justify-content: center;!important">
                <v-switch
                  @change="
                    switchControlChanged(
                      { active: item.active },
                      item['.key'],
                      item
                    )
                  "
                  v-model="item.active"
                ></v-switch>
              </div>
            </template>

            <template v-slot:[`item.options`]="{ item }">
              <v-btn @click="edit(item)" small color="primary">Editar</v-btn>
              <v-btn
                class="ml-5 white--text"
                @click="
                  selectedItem = item;
                  modalDelete = true;
                "
                small
                color="red darken-4"
                >Eliminar</v-btn
              >
            </template>

            <template v-slot:loading>
              <div class="ma-5" style="position: relative">
                <div
                  style="
                    position: absolute;
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                  "
                >
                  <lottie
                    :options="defaultOptions"
                    :height="300"
                    :width="400"
                    v-on:animCreated="handleAnimation"
                  />
                  <p
                    :class="
                      $vuetify.theme.dark
                        ? 'subtitle-1 primary--text'
                        : 'subtitle-1 black--text'
                    "
                  >
                    Cargando Data
                  </p>
                </div>
                <v-skeleton-loader
                  ref="skeleton"
                  type="table-tbody"
                  class="mx-auto d-none d-md-block"
                ></v-skeleton-loader>
              </div>
            </template>
          </v-data-table>
        </v-container>
      </v-card-text>
    </v-card>

    <v-dialog persistent v-model="addDialog" v-if="addDialog" max-width="650px">
      <add
        :position="tags.length + 1"
        :category="category"
        :selectedItem="selectedItem"
        @cancel="
          addDialog = false;
          selectedItem = null;
        "
        @success="tagAdded"
      />
    </v-dialog>

    <v-dialog v-model="sortDialog" v-if="sortDialog" max-width="500px">
      <sort
        @success="handleSort"
        @cancel="sortDialog = false"
        :categoryKey="category['.key']"
        :tags="tags"
      ></sort>
    </v-dialog>
    <!-- 
         <v-dialog persistent v-if="sectionSelected" v-model="modalEditSection" max-width="800px">
            <edit-section :key="sectionSelected['.key']" @success="handleUpdate" @cancel='modalEditSection=false' :section="sectionSelected" :category="category"></edit-section>
        </v-dialog>

        

         <v-dialog v-model="sortDialog" max-width="500px">
            <sort @success="handleSort" :key="`sort-${randomKey}`" @cancel='sortDialog=false' :categoryKey="category['.key']" :sections='sections' ></sort>
        </v-dialog> -->

    <v-dialog max-width="400" v-model="modalDelete" v-if="selectedItem">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar {{ selectedItem.name }}?
        </v-card-text>

        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from "vuex";
// import editSection from './edit-section'
import add from "./add";
import sort from "./sort";

export default {
  name: "sections",
  props: ["category"],
  components: {
    lottie: Lottie,
    add,
    // editSection,
    // addSection,
    sort,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      loading: true,
      addDialog: false,
      sortDialog: false,
      modalDelete: false,
      selectedItem: null,
      search: null,
      tags: [],
      headers: [
        { value: "image" },
        { text: "Posición", value: "position" },
        { text: "Nombre", value: "name", width: "200px" },
        { text: "Visible al filtrar categorías", value: "categoryVisible" },
        { text: "Activo", value: "active" },
        { value: "options", align: "end", width: "250px" },
      ],
    };
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    tagAdded() {
      this.snackbarText = `Etiqueta ${
        this.selectedItem ? "editada" : "agregada"
      } correctamente.`;
      this.snackbar = true;
      this.addDialog = false;
    },

    edit(item) {
      this.selectedItem = null;
      this.selectedItem = item;
      this.addDialog = true;
    },

    handleSort() {
      this.sortDialog = false;
      this.snackbarText = "Posiciones actualizadas correctamente.";
      this.snackbar = true;
    },

    confirmDelete() {
      if (this.selectedItem) {
        db.collection(`categories/${this.category[".key"]}/tags`)
          .doc(this.selectedItem[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Etiqueta eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    switchControlChanged(data, tagId, tag) {
      if (data && tagId) {
        data.modifiedAt = new Date();
        data.modifiedBy = this.$store.state.user[".key"];
        this.loading = true;
        db.collection(`categories/${this.category[".key"]}/tags`)
          .doc(tagId)
          .update(data)
          .then((ref) => {
            this.loading = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.loading = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            // tag.active = !tag.active;
          });
      }
    },
  },
  async mounted() {
    await this.$binding(
      "tags",
      db
        .collection(`categories/${this.category[".key"]}/tags`)
        .where("deleted", "==", false)
    );
    this.loading = false;
  },
};
</script>
