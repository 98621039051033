<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12" sm="11" md="11">
        <h1>Categorias</h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          @change="getCities"
          style="width: 90%"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-8"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCity"
          :items="cities"
          style="width: 90%"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          @change="getCategories"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-8"
        />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-row justify="end">
          <v-btn
            color="primary"
            class="ma-2 white--text mr-5"
            @click="
              modalSort = true;
              randomKey = Math.random();
            "
            :loading="loading"
            :disabled="!selectedCity"
          >
            <v-icon right dark class="mr-3">fas fa-bars</v-icon>
            Ordenar
          </v-btn>
          <v-btn
            color="primary"
            class="ma-2 white--text"
            :disabled="!selectedCity"
            @click="addCategory()"
            :loading="loading"
          >
            <v-icon right dark class="mr-3">fas fa-plus</v-icon>
            Crear nueva categoria
          </v-btn>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="categories"
          :items-per-page="15"
          :search="$store.state.search"
          :loading="loading"
          item-key=".key"
          :sort-by="['position']"
          :sort-desc="[false, true]"
          :single-expand="true"
          fixed-header
          height="75vh"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position: relative">
              <div
                style="
                  position: absolute;
                  z-index: 999;
                  width: 100%;
                  height: 100%;
                  display: flex;
                  flex-direction: column;
                  justify-content: center;
                  align-items: center;
                "
              >
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.image`]="{ item }">
            <v-avatar :color="item.imageColor || 'grey'">
              <v-img
                v-if="item.image"
                :src="item.image.original"
                :alt="item.name"
                contain
                :lazy-src="item.image.loading"
              ></v-img>
            </v-avatar>
          </template>

          <template v-slot:[`item.isHomeAvailable`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isHomeAvailable: item.isHomeAvailable },
                  item['.key'],
                  item
                )
              "
              v-model="item.isHomeAvailable"
            ></v-switch>
          </template>

          <template v-slot:[`item.isCongested`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { isCongested: item.isCongested },
                  item['.key'],
                  item
                )
              "
              v-model="item.isCongested"
            ></v-switch>
          </template>

          <template v-slot:[`item.closed`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { closed: item.closed },
                  item['.key'],
                  item
                )
              "
              v-model="item.closed"
            ></v-switch>
          </template>

          <template v-slot:[`item.color`]="{ item }">
            <v-avatar :color="item.color"></v-avatar>
          </template>

          <template v-slot:[`item.city`]="{ item }">
            <v-chip color="gray">
              {{ getCityName(item.city) }}
            </v-chip>
          </template>

          <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | filterDate }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editCategory(item)" small color="primary">
              Editar
            </v-btn>

            <!-- <v-btn @click="editPromos(item)" small color="primary" class="ml-3">
              Promos
            </v-btn> -->

            <v-btn
              @click="editSections(item)"
              small
              color="primary"
              class="ml-3"
            >
              Secciones
            </v-btn>

            <!-- <v-btn
              @click="
                editTags(item)
              "
              small
              color="primary"
              class="ml-3"
            >
              Etiquetas
            </v-btn> -->

            <v-btn
              @click="deleteCategory(item)"
              small
              color="red darken-4"
              class="ml-3 white--text"
            >
              Eliminar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog
      persistent
      v-if="categorySelected"
      v-model="modalEditSections"
      max-width="70%"
    >
      <sections
        @cancel="modalEditSections = false"
        @success="handleUpdate"
        :key="categorySelected['.key']"
        :category="categorySelected"
      >
      </sections>
    </v-dialog>

    <v-dialog
      persistent
      v-if="categorySelected"
      v-model="modalEditPromos"
      max-width="80%"
    >
      <promos
        @cancel="modalEditPromos = false"
        @success="handleUpdate"
        :key="categorySelected['.key']"
        :category="categorySelected"
        :city="selectedCity"
      >
      </promos>
    </v-dialog>

    <v-dialog
      persistent
      v-if="categorySelected"
      v-model="tagsDialog"
      max-width="70%"
    >
      <tags
        @cancel="tagsDialog = false"
        @success="handleUpdate"
        :key="categorySelected['.key']"
        :category="categorySelected"
      />
    </v-dialog>

    <v-dialog v-model="modalNewCategory" persistent max-width="70%">
      <new-category
        :key="randomKey"
        :editedCategory="categorySelected"
        :type="type"
        @cancel="modalNewCategory = false"
        @success="handleUpdate"
        :city="selectedCity"
        :country="selectedCountry"
      >
      </new-category>
    </v-dialog>

    <v-dialog v-model="modalSort" max-width="500px">
      <sort
        :key="`sort-${randomKey}`"
        @cancel="modalSort = false"
        :city="selectedCity"
        :categories="categories"
        @success="handleSorted"
      >
      </sort>
    </v-dialog>

    <v-dialog max-width="400" v-model="modalDelete" v-if="itemToDelete">
      <v-card>
        <v-card-title class="headline"
          >Confirmar acción
          <v-btn
            style="right: 10px; top: 10px"
            icon
            color="primary"
            fab
            absolute
            @click="modalDelete = false"
          >
            <v-icon dark>fa-times</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          ¿Está seguro que desea eliminar
          <span class="font-weight-bold">{{ itemToDelete.name }}</span
          >?
        </v-card-text>
        <v-card-actions style="justify-content: center">
          <v-btn
            color="red darken-4"
            width="90%"
            style="border-radius: 50px"
            class="mb-3"
            dark
            @click="confirmDelete"
          >
            Eliminar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import newCategory from "./new-category";
import sections from "./sections/sections";
import promos from "./promos/promos";
import sort from "./sort";
import tags from "./tags/tags";

export default {
  name: "categories",
  components: {
    lottie: Lottie,
    newCategory,
    sections,
    promos,
    sort,
    tags,
  },
  data() {
    return {
      loading: true,
      saving: false,
      countries: [],
      selectedCountry: "",
      selectedCity: "",
      type: "",
      randomKey: 0,
      snackbar: false,
      modalEditSections: false,
      modalEditPromos: false,
      snackbarText: "",
      itemToEdit: {},
      categories: [],
      cities: [],
      itemToDelete: null,
      modalDelete: false,
      modalNewCategory: false,
      tagsDialog: false,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },
      anim: null,
      animationSpeed: 1.1,
      categorySelected: null,
      modalSort: false,
      headers: [
        {
          text: "Posición",
          value: "position",
          class: "nowrap",
        },
        {
          text: "Imágen",
          value: "image",
          class: "nowrap",
        },
        {
          text: "Color",
          value: "color",
          class: "nowrap",
        },
        {
          text: "Nombre",
          value: "name",
          class: "nowrap",
        },

        {
          text: "Tipo",
          value: "type",
          class: "nowrap",
        },

        {
          text: "Activo",
          value: "active",
          class: "nowrap",
        },
        {
          text: "Aparece en Lobby",
          value: "isHomeAvailable",
          class: "nowrap",
        },
        {
          text: "Categoría Saturada",
          value: "isCongested",
          class: "nowrap",
        },
        {
          text: "Cerrado",
          value: "closed",
          class: "nowrap",
        },
        {
          value: "options",
          align: "end",
          width: "500px",
        },
      ],
    };
  },

  filters: {
    filterDate(timestamp) {
      if (!timestamp) return "no disponible";
      return moment(timestamp.toDate()).tz("America/Tegucigalpa").format("LL");
    },
  },

  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    getCityName(cityId) {
      if (cityId)
        return this.cities.find((item) => item[".key"] == cityId).name;
    },

    handleSorted() {
      this.snackbarText = "Categorías actualizadas exitosamente.";
      this.snackbar = true;
      this.modalSort = false;
    },

    getCities(countryId) {
      this.categories = [];
      this.cities = [];
      this.selectedCity = "";

      db.collection("cities")
        .where("deleted", "==", false)
        .where("countryId", "==", countryId)
        .get()
        .then((response) => {
          response.forEach((item) => {
            let city = item.data();
            city[".key"] = item.id;
            this.cities.push(city);
          });
        });
    },

    async getCategories(cityId) {
      this.loading = true;
      // this.categories = [];
      await this.$binding(
        "categories",
        db
          .collection("categories")
          .where("deleted", "==", false)
          .where("city", "==", cityId)
      );

      this.loading = false;
    },

    addCategory() {
      this.type = null;
      this.randomKey = Math.random();
      this.modalNewCategory = true;
    },

    handleUpdate(data) {
      // if (this.type == "edit") {
      //   this.categories = this.categories.map((item) => {
      //     if (item[".key"] == this.categorySelected[".key"]) {
      //       item.active = data.active;
      //       item.color = data.color;
      //       item.extraFees = data.extraFees;
      //       item.icon = data.icon;
      //       item.imageColor = data.imageColor;
      //       item.isHomeAvailable = data.isHomeAvailable;
      //       item.isCongested = data.isCongested;
      //       item.kmApply = data.kmApply;
      //       item.kmConfiguration = data.kmConfiguration;
      //       item.name = data.name;
      //       item.position = data.position;
      //       item.promosSectionTitle = data.promosSectionTitle;
      //       item.showTime = data.showTime;
      //       item.tags = data.tags;
      //       item.type = data.type;
      //       item.size = data.size;
      //     }
      //     return item;
      //   });
      //   this.snackbarText = "Categoría actualizada correctamente.";
      // } else {
      //   this.snackbarText = "Categoría agregada correctamente.";
      // this.categories.push(data);
      // }
      this.snackbarText = this.categorySelected
        ? "Categoría actualizada correctamente."
        : "Categoría agregada correctamente.";
      this.modalNewCategory = false;
      this.snackbar = true;
    },

    editCategory(item) {
      this.categorySelected = item;
      this.type = "edit";
      this.randomKey = Math.random();
      this.modalNewCategory = true;
    },

    editSections(item) {
      this.categorySelected = item;
      this.modalEditSections = true;
    },

    editPromos(item) {
      this.categorySelected = item;
      this.modalEditPromos = true;
    },

    editTags(item) {
      this.categorySelected = item;
      this.tagsDialog = true;
    },

    deleteCategory(item) {
      this.itemToDelete = item;
      this.modalDelete = true;
    },

    confirmDelete() {
      if (this.itemToDelete) {
        db.collection("categories")
          .doc(this.itemToDelete[".key"])
          .update({
            deleted: true,
            active: false,
            deletedAt: new Date(),
            deletedBy: this.$store.state.user[".key"],
          })
          .then(() => {
            // this.categories = this.categories.filter(
            //   (item) => item[".key"] != this.itemToDelete[".key"]
            // );
            this.modalDelete = false;
            this.snackbar = true;
            this.snackbarText = "Categoría eliminada";
          })
          .catch((error) => {
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    switchControlChanged(data, catId, cat) {
      if (data && catId) {
        this.saving = true;
        db.collection("categories")
          .doc(catId)
          .update(data)
          .then((ref) => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;

            if (data.hasOwnProperty("active")) cat.active = !cat.active;
            else if (data.hasOwnProperty("isHomeAvailable"))
              cat.isHomeAvailable = !cat.isHomeAvailable;
          });
      }
    },
  },

  mounted() {
    // document
    //   .getElementsByClassName("v-data-table__wrapper")[0]
    //   .addEventListener("scroll", function (e) {
    //     document.body.scrollTop = document.documentElement.scrollTop =
    //       document.getElementsByClassName("v-data-table__wrapper")[0].scrollTop;
    //   });

    this.$store.commit("setSearchTerm", "categoria");
    this.$store.commit("setSearch", "");
    this.$store.state.visibleSearch = true;

    db.collection("countries")
      .where("deleted", "==", false)
      .get()
      .then((response) => {
        response.forEach((item) => {
          let country = item.data();
          country[".key"] = item.id;
          this.countries.push(country);
        });
        this.loading = false;
      });
  },
};
</script>
