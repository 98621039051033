var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-card',[_c('v-card-text',{staticStyle:{"max-height":"65vh","min-height":"65vh","overflow-y":"scroll"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"7","sm":"7"}},[_c('v-text-field',{staticClass:"d-none d-md-flex",attrs:{"clearable":"","rounded":"","flat":"","filled":"","hide-details":"","label":`Buscar etiqueta`,"prepend-inner-icon":"fa-search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","md":"5","sm":"5"}},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-btn',{staticClass:"ma-2 white--text mr-5",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.tags.length <= 1},on:{"click":function($event){_vm.sortDialog = true}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-bars")]),_vm._v(" Ordenar ")],1),_c('v-btn',{staticClass:"ma-2 white--text",attrs:{"color":"primary","loading":_vm.loading},on:{"click":function($event){_vm.addDialog = true;
                  _vm.selectedItem = null;}}},[_c('v-icon',{staticClass:"mr-3",attrs:{"right":"","dark":""}},[_vm._v("fas fa-plus")]),_vm._v(" Agregar etiqueta ")],1)],1)],1)],1),_c('v-data-table',{staticClass:"mt-5",attrs:{"headers":_vm.headers,"items":_vm.tags,"search":_vm.search,"items-per-page":5,"fixed-header":"","loading":_vm.loading,"sort-by":['position'],"sort-desc":[false, true],"item-key":"id","footer-props":{
            itemsPerPageOptions: [5],
          }},scopedSlots:_vm._u([{key:`item.categoryVisible`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                    { categoryVisible: item.categoryVisible },
                    item['.key'],
                    item
                  )}},model:{value:(item.categoryVisible),callback:function ($$v) {_vm.$set(item, "categoryVisible", $$v)},expression:"item.categoryVisible"}})],1)]}},{key:`item.image`,fn:function({ item }){return [(item.image)?_c('v-avatar',[_c('img',{attrs:{"src":item.image.original,"alt":"John"}})]):_vm._e()]}},{key:`item.active`,fn:function({ item }){return [_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('v-switch',{on:{"change":function($event){return _vm.switchControlChanged(
                    { active: item.active },
                    item['.key'],
                    item
                  )}},model:{value:(item.active),callback:function ($$v) {_vm.$set(item, "active", $$v)},expression:"item.active"}})],1)]}},{key:`item.options`,fn:function({ item }){return [_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.edit(item)}}},[_vm._v("Editar")]),_c('v-btn',{staticClass:"ml-5 white--text",attrs:{"small":"","color":"red darken-4"},on:{"click":function($event){_vm.selectedItem = item;
                _vm.modalDelete = true;}}},[_vm._v("Eliminar")])]}},{key:"loading",fn:function(){return [_c('div',{staticClass:"ma-5",staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"999","width":"100%","height":"100%","display":"flex","flex-direction":"column","justify-content":"center","align-items":"center"}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}}),_c('p',{class:_vm.$vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'},[_vm._v(" Cargando Data ")])],1),_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block",attrs:{"type":"table-tbody"}})],1)]},proxy:true}],null,true)})],1)],1)],1),(_vm.addDialog)?_c('v-dialog',{attrs:{"persistent":"","max-width":"650px"},model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('add',{attrs:{"position":_vm.tags.length + 1,"category":_vm.category,"selectedItem":_vm.selectedItem},on:{"cancel":function($event){_vm.addDialog = false;
        _vm.selectedItem = null;},"success":_vm.tagAdded}})],1):_vm._e(),(_vm.sortDialog)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.sortDialog),callback:function ($$v) {_vm.sortDialog=$$v},expression:"sortDialog"}},[_c('sort',{attrs:{"categoryKey":_vm.category['.key'],"tags":_vm.tags},on:{"success":_vm.handleSort,"cancel":function($event){_vm.sortDialog = false}}})],1):_vm._e(),(_vm.selectedItem)?_c('v-dialog',{attrs:{"max-width":"400"},model:{value:(_vm.modalDelete),callback:function ($$v) {_vm.modalDelete=$$v},expression:"modalDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Confirmar acción "),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){_vm.modalDelete = false}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-card-text',[_vm._v(" ¿Está seguro que desea eliminar "+_vm._s(_vm.selectedItem.name)+"? ")]),_c('v-card-actions',{staticStyle:{"justify-content":"center"}},[_c('v-btn',{staticClass:"mb-3",staticStyle:{"border-radius":"50px"},attrs:{"color":"red darken-4","width":"90%","dark":""},on:{"click":_vm.confirmDelete}},[_vm._v(" Eliminar ")])],1)],1)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }