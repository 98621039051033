<template>
  <v-dialog :max-width="selectedBusiness ? '1100' : '700'" v-model="visible">
    <v-card>
      <v-card-title class="headline">
        <span class="headline">Productos de la promoción</span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>

        <v-text-field
          v-model="search"
          clearable
          :loading="loading"
          v-if="!selectedBusiness"
          rounded
          flat
          filled
          hide-details
          :label="`Buscar comercio`"
          prepend-inner-icon="fa-search"
          style="width: 100%"
          class="d-none d-md-flex mt-5 mb-0"
        />
      </v-card-title>
      <v-card-text style="max-height: 60vh; min-height: 60vh">
        <v-container>
          <v-overlay class="loading-center" :value="saving">
            <lottie
              :options="defaultOptions"
              :height="300"
              :width="400"
              v-on:animCreated="handleAnimation"
            />
          </v-overlay>

          <v-row>
            <v-col
              cols="12"
              :md="selectedBusiness ? '5' : '12'"
              :sm="selectedBusiness ? '5' : '12'"
            >
              <v-row>
                <v-text-field
                  v-model="search"
                  clearable
                  v-if="selectedBusiness"
                  rounded
                  flat
                  filled
                  hide-details
                  :label="`Buscar comercio`"
                  prepend-inner-icon="fa-search"
                  style="width: 100%"
                  class="d-none d-md-flex mb-0"
                />
                <v-col v-if="!selectedBusiness" cols="12" sm="12" md="12">
                  <p>Resultados</p>
                  <v-divider></v-divider>
                </v-col>

                <v-list
                  v-if="loading"
                  class="mt-4"
                  style="
                    background: white !important;
                    max-height: 48vh;
                    min-height: 48vh;
                    overflow-y: scroll;
                    min-width: 100%;
                  "
                >
                  <v-list-item-group color="primary">
                    <v-list-item
                      v-for="(item, index) in [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                      :key="index"
                    >
                      <v-list-item-content>
                        <v-skeleton-loader
                          ref="skeleton"
                          type="list-item"
                          class="mx-auto"
                        ></v-skeleton-loader>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>

                <v-list
                  v-if="!loading"
                  class="mt-4"
                  style="
                    background: white !important;
                    max-height: 48vh;
                    min-height: 48vh;
                    overflow-y: scroll;
                    min-width: 100%;
                  "
                >
                  <v-list-item-group v-model="item" color="primary">
                    <v-list-item
                      :disabled="loadingProducts"
                      v-for="business in getBusinesses"
                      :key="business['.key']"
                      @click="selectBusiness(business)"
                    >
                      <v-list-item-avatar>
                        <v-img
                          v-if="business.logo"
                          :src="business.logo.original"
                        ></v-img>
                      </v-list-item-avatar>

                      <v-list-item-content>
                        <v-list-item-title>{{
                          business.shortName
                        }}</v-list-item-title>
                        <v-list-item-subtitle
                          v-html="business.description"
                        ></v-list-item-subtitle>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list-item-group>
                </v-list>
              </v-row>
            </v-col>

            <v-col v-if="selectedBusiness" cols="12" md="7" sm="7">
              <h3 class="font-weight-bold mt-5 ml-5">
                Seleccionar productos de {{ selectedBusiness.shortName }}
              </h3>
              <v-divider></v-divider>
              <v-row v-if="loadingProducts">
                <v-col cols="6" sm="6" md="6">
                  <v-skeleton-loader
                    ref="skeleton"
                    v-for="(item, index) of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                    :key="index"
                    type="list-item"
                    style="width: 100%"
                  ></v-skeleton-loader>
                </v-col>
                <v-col cols="6" sm="6" md="6">
                  <v-skeleton-loader
                    ref="skeleton"
                    v-for="(item, index) of [1, 2, 3, 4, 5, 6, 7, 8, 9]"
                    :key="index"
                    type="list-item"
                    style="width: 100%"
                  ></v-skeleton-loader>
                </v-col>
              </v-row>

              <a-transfer
                class="mt-3 ml-5"
                :listStyle="{
                  height: '48vh!important',
                  width: '44%',
                  background: 'white',
                }"
                :data-source="businessProducts"
                :titles="['Todas las productos', 'Productos agregados']"
                :target-keys="selectedProducts"
                :selected-keys="selectedKeys"
                :show-search="true"
                v-if="!loadingProducts"
                @search="handleSearch"
                :render="(item) => item.title"
                :filter-option="filterOption"
                @change="handleChange"
                @selectChange="handleSelectChange"
                :locale="{
                  itemUnit: '',
                  itemsUnit: '',
                  notFoundContent: 'Lista vacía',
                  searchPlaceholder: 'Buscar sección',
                }"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions v-if="selectedBusiness">
        <div style="width: 100%; text-align: end">
          <v-divider class="mt-5"></v-divider>
          <v-btn
            class="save-btn mt-5 mb-3 mr-3"
            color="primary"
            @click="success"
            >Guardar</v-btn
          >
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";
// import axios from "axios";
import transfer from "ant-design-vue/lib/transfer";

export default {
  name: "add-remove-products",
  props: ["promo", "category"],
  components: {
    lottie: Lottie,
    "a-transfer": transfer,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      color: "",
      snackbarText: "",
      saving: false,
      selectedKeys: [],
      loading: true,
      businesses: [],
      newTarget: [],
      selectedProducts: [],
      menu: false,
      visible: false,
      item: null,
      search: "",
      selectedBusiness: null,
      businessProducts: [],
      loadingProducts: false,
      roles: ["sudo", "admin", "AC", "EFCM"],
    };
  },
  async mounted() {
    this.visible = true;

    this.newTarget = Object.assign([], this.promo.target);

    if (this.roles.includes(this.user.type)) {
      await this.$binding(
        "businesses",
        db
          .collection("businesses")
          .where("deleted", "==", false)
          .where("ochoCategories", "array-contains", this.category[".key"])
      );
      this.loading = false;
    } else {
      let arrayCopy = Object.assign([], this.user.businesses);

      arrayCopy.forEach((id, index) => {
        if (index % 10 == 0) {
          let array = arrayCopy.slice(index, index + 10);

          db.collection(`businesses`)
            .where(fb.firestore.FieldPath.documentId(), "in", array)
            .get()
            .then((businesses) => {
              this.loading = false;
              businesses.forEach((business) => {
                let item = business.data();
                item[".key"] = business.id;

                this.businesses.push(item);
              });
            });
        }
      });
    }
  },
  computed: {
    ...mapState(["user"]),
    getBusinesses() {
      if (this.search)
        return this.businesses.filter((item) =>
          item.shortName.toUpperCase().includes(this.search.toUpperCase())
        );
      else {
        return this.businesses;
      }
    },
  },
  methods: {
    handleAnimation(anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },
    success() {
      this.$emit("success", this.newTarget);
    },
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.selectedProducts = nextTargetKeys;

      if (direction == "right") {
        nextTargetKeys.forEach((productKey) => {
          if (!this.newTarget.find((item) => item.product == productKey)) {
            this.newTarget.push({
              business: this.selectedBusiness[".key"],
              product: productKey,
              productName: this.businessProducts.find(
                (item) => item.key == productKey
              ).title,
              businessName: this.selectedBusiness.shortName,
              isChildOf: this.selectedBusiness.isChildOf || null,
            });
          }
        });
      } else if (direction == "left") {
        moveKeys.forEach((productKey) => {
          this.newTarget = this.newTarget.filter(
            (item) => item.product != productKey
          );
        });
      }
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },

    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },

    selectBusiness(business) {
      this.selectedBusiness = business;

      this.loadingProducts = true;

      let query = db
        .collection(`products`)
        .where("business", "array-contains", business[".key"])
        .where("deleted", "==", false)
        .where("addon", "==", false);

      this.$binding("businessProducts", query).then((products) => {
        this.businessProducts = products.map((item) => {
          return {
            title: item.name,
            key: item[".key"],
          };
        });
        this.loadingProducts = false;
      });

      let target = this.newTarget.filter(
        (item) => item.business == business[".key"]
      );

      this.selectedProducts = target.map((item) => {
        return item.product;
      });
    },
  },
};
</script>