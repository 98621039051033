<template>
<v-dialog v-model="visible" max-width="1100">
    <v-snackbar top :timeout="3000" v-model="snackbar">{{snackbarText}}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
        <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
        />
    </v-overlay>

      <v-card>
        <v-card-title class="grid-close">
            <span class="headline">Comercios de la promoción</span>
            <v-btn
                style="right:10px; top:10px;"
                icon
                color="primary"
                fab
                absolute
                @click="$emit('cancel')"
                >
                <v-icon dark>fa-times</v-icon>
            </v-btn>
      </v-card-title>
    <v-card-text>
    
    <businesses-list-skeleton v-if="loading"/>
      
      <v-container v-if="!loading" fluid>
        <v-row>
            <v-col cols="12" sm="12" md="12">
               <a-transfer
                  v-if="businesses"
                  :listStyle="{
                      'height': '420px!important',
                    'width': '45%',
                      'padding' : 0,
                      'margin' : 0,
                      'background' : 'white'
                  }"
                  :data-source="getBusinesses"
                  :titles="['Todos los comercios', 'Comercios agregados']"
                  :target-keys="selectedBusinesses"
                  :selected-keys="selectedKeys"
                  :show-search="true"
                  @search="handleSearch"
                  :render="item => item.title"
                  :disabled="disabled"
                  :filter-option="filterOption"
                  @change="handleChange"
                  @selectChange="handleSelectChange"
                  @scroll="handleScroll"
                  :locale= "{ itemUnit: '', itemsUnit: '', notFoundContent: 'Lista vacía', searchPlaceholder: 'Buscar comercio' }"
                />
            </v-col>
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions v-if="!loading">
      <v-container fluid >
        <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="12" xs="12" sm="12" md="12">
                <v-row  align="center" justify="end">
                    <v-btn class="save-btn" @click="success" color="primary">Guardar</v-btn>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
    </v-card-actions>
    </v-card>

</v-dialog>
</template>

<script>

import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie"
import * as animationData from "../../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from 'vuex';
import axios from 'axios'
import transfer from 'ant-design-vue/lib/transfer';
import businessesListSkeleton from '../../skeleton/businesses-list-skeleton'


export default {
  name: "add-remove-products",
  props: ['promo', 'category'],
  components: {
    lottie: Lottie,
    'a-transfer' : transfer,
    businessesListSkeleton
  },
  data() {
   return {
        animationSpeed: 1.1,
        defaultOptions: {
            animationData: animationData.default,
            loop: true,
            autoplay: true
        },

        snackbar : false,
        color : '',
        snackbarText : '',
        saving : false,
        selectedKeys: [],
        loading : true,
        businesses : [],
        newBusinessTarget : [],
        selectedProducts: [],
        menu : false,
        visible: false,
        item : null,
        search: '',
        selectedBusiness : null,
        selectedBusinesses : [],
        loading : true,
        selectedKeys: [],
        disabled: false,
        businessProducts : [],
        loadingProducts: false,
    }
  },

  async mounted(){
    this.visible = true

    this.selectedBusinesses = this.promo.businessTarget.map( item => {
      return item.business
    })


    let businesses =  this.$binding("businesses", db.collection("businesses")
      .where('deleted', '==', false)
      .where("ochoCategories", "array-contains", this.category['.key'])
    )
    .then((businesses) => {
        this.businesses = businesses.map(item => {
                    return {
                        isChildOf : item.isChildOf || null,
                        title : item.shortName,
                        key : item['.key']
                    }
                })
        this.loading = false
    })


  },

  computed : {
      getBusinesses() {
          if ( this.search )
            return this.businesses.filter( item => item.shortName.toUpperCase().includes(this.search.toUpperCase()))
          else
            return this.businesses
      }
  },
  methods: {
    
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.selectedBusinesses = nextTargetKeys;

      // if ( direction == 'right') {
      //    nextTargetKeys.forEach( businessKey  => {
      //       if ( !this.newBusinessTarget.find( item => item.business == businessKey)) {
      //           this.newBusinessTarget.push({
      //               business : businessKey,
      //               businessName : this.businesses.find(item => item.key == businessKey).title,
      //               isChildOf : this.businesses.find(item => item.key == businessKey).isChildOf,
      //           })
      //       }
            
      //   })
      // } else if (direction == 'left') {
      //     moveKeys.forEach( businessKey => {
      //         this.newBusinessTarget = this.newBusinessTarget.filter( item => item.business != businessKey)
      //     })
      // }

    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
     filterOption(inputValue, option) {
       
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    handleScroll(direction, e) {
     
    },
    handleDisable(disabled) {
      this.disabled = disabled;
    },

    handleAnimation: function(anim) {
        this.anim = anim;
        anim.setSpeed(this.animationSpeed);
    },
    success() {
        let newTarget = this.businesses.filter( item => this.selectedBusinesses.includes(item.key))
        newTarget = newTarget.map( item => {
          return {
            businessName : item.title,
            business : item.key,
            isChildOf : item.isChildOf
          }
        })
        this.$emit('success', newTarget)
    },

  }
}
</script>