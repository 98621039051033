<template>
  <div>
    <v-snackbar absolute top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>
    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ type == "edit" ? "Editar" : "Nueva" }} categoría
          {{ newCategory.name }}
        </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid style="min-height: 300px">
          <v-tabs v-model="tab" align-with-title>
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="general"> General </v-tab>
            <v-tab key="tags">Etiquetas </v-tab>
            <v-tab key="km">Configuración de KM </v-tab>
            <v-tab key="fees">Tarifas adicionales</v-tab>
            <v-tab key="schedule"> Horarios </v-tab>
            <v-tab key="state"> Estados </v-tab>
          </v-tabs>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <v-tabs-items v-model="tab">
                <v-tab-item key="general">
                  <General
                    @imageSelected="setImageFile"
                    :newCategory="newCategory"
                  />
                </v-tab-item>

                <v-tab-item key="tags">
                  <Tags
                    @changed="tagsChanged"
                    :country="country"
                    ref="tags"
                    :newCategory="newCategory"
                    :allTags="allTags"
                  />
                </v-tab-item>

                <v-tab-item key="km">
                  <Km :newCategory="newCategory" />
                </v-tab-item>

                <v-tab-item key="fees">
                  <Fees @change="extraFeesChanged" :newCategory="newCategory" />
                </v-tab-item>

                <v-tab-item key="schedule">
                  <Schedule :schedule="scheduleCopy" />
                </v-tab-item>

                <v-tab-item key="state">
                  <Status :newCategory="newCategory" />
                </v-tab-item>
              </v-tabs-items>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch v-model="newCategory.active" label="Categoría activa">
                </v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="type == 'edit'"
                  @click="updateCategory()"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
                <v-btn
                  v-else
                  @click="addCategory()"
                  class="save-btn mt-4"
                  color="primary"
                >
                  Guardar
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie";
import * as animationData from "../../../assets/ochoColor.json";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

import General from "./tabs/general.vue";
import Km from "./tabs/km.vue";
import Fees from "./tabs/extra-fees.vue";
import Status from "./tabs/status.vue";
import Schedule from "@/components/own-schedule";
import Tags from "./tabs/tags.vue";

export default {
  name: "new-category",
  props: ["city", "type", "editedCategory", "country"],
  components: {
    lottie: Lottie,
    General,
    Km,
    Fees,
    Status,
    Schedule,
    Tags,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      tab: "",
      snackbarText: "",
      saving: false,
      loading: true,
      selectedTags: [],
      allTags: [],
      scheduleCopy: {
        monday: [],
        tuesday: [],
        wednesday: [],
        thursday: [],
        friday: [],
        saturday: [],
        sunday: [],
      },
      newCategory: {
        position: "",
        name: "",
        isHomeAvailable: false,
        isCongested: false,
        nickname: "",
        city: "",
        size: "small",
        promosSectionTitle: "",
        description: "",
        active: false,
        type: "",
        color: "#F96921",
        icon: "",
        imageColor: "#636363",
        kmApply: false,
        showTime: false,
        deleted: false,
        hideBackground: false,
        kmConfiguration: {
          dayCostKm: 0,
          dayRange: 0,
          extraKmCharge: 0,
          maxDistance: 0,
          dayStarts: "07:00:00",
          dropoffDay: 0,
          dropoffNight: 0,
          nightCostKm: 0,
          nightRange: 0,
          nightStarts: "18:00:00",
          pickupDay: 0,
          pickupNight: 0,
        },
      },
      imageFile: null,
      fullImageFile: null,
    };
  },
  async mounted() {
    if (this.type == "edit") {
      this.newCategory = Object.assign({}, this.editedCategory);

      this.scheduleCopy = this.newCategory.schedule
        ? this.newCategory.schedule
        : this.scheduleCopy;
    }

    await this.$binding(
      "allTags",
      db.collection("countries").doc(this.country).collection("tags")
    );

    this.loading = false;
  },
  computed: {
    validate() {
      if (
        !this.newCategory.name ||
        !this.newCategory.color ||
        !this.newCategory.type
      ) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return false;
      } else if (
        !this.newCategory.kmConfiguration.dayCostKm < 0 ||
        !this.newCategory.kmConfiguration.dayRange < 0 ||
        !this.newCategory.kmConfiguration.pickupDay < 0 ||
        !this.newCategory.kmConfiguration.dayStarts ||
        !this.newCategory.kmConfiguration.dropoffDay < 0 ||
        !this.newCategory.kmConfiguration.nightCostKm < 0 ||
        !this.newCategory.kmConfiguration.pickupNight < 0 ||
        !this.newCategory.kmConfiguration.dropoffNight < 0 ||
        !this.newCategory.kmConfiguration.nightRange < 0 ||
        !this.newCategory.kmConfiguration.nightStarts
      ) {
        this.snackbarText = "Debe llenar todos los campos.";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    moment,
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    extraFeesChanged(e) {
      this.newCategory.extraFees = e;
    },

    setImageFile(e) {
      if (e.imageType == "smallImage") {
        this.imageFile = e.smallImageFile;
      } else if (e.imageType == "fullImage") {
        this.fullImageFile = e.fullImageFile;
      }
    },
    tagsChanged(e) {
      this.selectedTags = e;
    },
    updateTags(categoryId) {
      return new Promise(async (resolve, reject) => {
        if (this.$refs.tags) {
          for (let i = 0; i < this.allTags.length; i++) {
            const tagId = this.allTags[i][".key"];
            const tag = this.allTags[i];

            let reference = db
              .collection("countries")
              .doc(this.country)
              .collection("tags")
              .doc(tagId);

            if (
              (!tag.categories && this.selectedTags.includes(tagId)) ||
              (this.selectedTags.includes(tagId) &&
                !tag.categories.includes(categoryId))
            ) {
              await reference.update({
                categories: fb.firestore.FieldValue.arrayUnion(categoryId),
              });
            }

            if (
              tag.categories &&
              tag.categories.includes(categoryId) &&
              !this.selectedTags.includes(tagId)
            ) {
              await reference.update({
                categories: fb.firestore.FieldValue.arrayRemove(categoryId),
              });
            }
          }
        }
        return resolve("success");
      });
    },

    uplodaImage(categoryId, imageType) {
      return new Promise((resolve, reject) => {
        let image =
          imageType == "smallImage" ? this.imageFile : this.fullImageFile;

        fb.app()
          .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-categories`)
          .ref()
          .child(`${categoryId}/${imageType}/${image.name}`)
          .put(image)
          .then((res) => {
            return resolve("success");
          })
          .catch((err) => {
            return reject(err);
          });
      });
    },

    addCategory() {
      if (this.validate) {
        this.saving = true;
        this.newCategory.city = this.city;
        this.newCategory.schedule = this.scheduleCopy ? this.scheduleCopy : {};
        this.newCategory.createdAt = new Date();
        this.newCategory.createdBy = this.$store.state.user[".key"];

        db.collection("categories")
          .add(this.newCategory)
          .then(async (ref) => {
            await this.updateTags(ref.id);

            if (this.imageFile) {
              await this.uplodaImage(ref.id, "smallImage");
            }

            if (this.fullImageFile) {
              await this.uplodaImage(ref.id, "fullImage");
            }

            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    updateCategory() {
      if (this.validate) {
        let category = {
          name: this.newCategory.name,
          color: this.newCategory.color,
          icon: this.newCategory.icon,
          type: this.newCategory.type,
          position: this.newCategory.position,
          size: this.newCategory.size ? this.newCategory.size : "",
          kmConfiguration: this.newCategory.kmConfiguration,
          active: this.newCategory.active,
          kmApply: this.newCategory.kmApply,
          showTime: this.newCategory.showTime,
          promosSectionTitle: this.newCategory.promosSectionTitle || "",
          schedule: this.scheduleCopy ? this.scheduleCopy : {},
          isHomeAvailable: this.newCategory.hasOwnProperty("isHomeAvailable")
            ? this.newCategory.isHomeAvailable
            : false,
          imageColor: this.newCategory.imageColor
            ? this.newCategory.imageColor
            : "",
          extraFees: this.newCategory.extraFees || [],
          showFavorite: this.newCategory.showFavorite || false,
          closed: this.newCategory.closed || false,
          hideName: this.newCategory.hideName || false,
          description: this.newCategory.description || "",
          hideBackground: this.newCategory.hideBackground || false,
          showTags: this.newCategory.showTags || false,
        };
        this.saving = true;

        db.collection("categories")
          .doc(this.newCategory[".key"])
          .update(category)
          .then(async () => {
            await this.updateTags(this.newCategory[".key"]);
            if (this.imageFile) {
              await this.uplodaImage(this.newCategory[".key"], "smallImage");
            }

            if (this.fullImageFile) {
              await this.uplodaImage(this.newCategory[".key"], "fullImage");
            }
            this.saving = false;
            this.$emit("success");
          })
          .catch((err) => {
            console.log(err);
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
            this.saving = false;
          });
      }
    },
  },
};
</script>

<style scoped>
.v-tab {
  margin-left: 0 !important;
}
</style>
