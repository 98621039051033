<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" md="6">
        <p>Costo de Envío Fijo</p>
        <v-text-field
          rounded
          @keypress="isNumber"
          outlined
          v-model.number="newCategory.kmConfiguration.kmFixedFee"
        />
      </v-col>

      <v-col cols="6" md="6">
        <v-switch
          class="mt-10 pt-2"
          v-model="newCategory.kmConfiguration.isKmFixedFee"
          label="Activar costo de envío fijo"
        ></v-switch>
      </v-col>

      <v-col cols="6" md="6">
        <p>Costo extra por distancia máxima</p>
        <v-text-field
          rounded
          @keypress="isNumber"
          outlined
          v-model.number="newCategory.kmConfiguration.extraKmCharge"
        />
      </v-col>

      <v-col cols="6" md="6">
        <p>Km para aplicar distancia máxima</p>
        <v-text-field
          rounded
          @keypress="isNumber"
          outlined
          v-model.number="newCategory.kmConfiguration.maxDistance"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <v-row style="border-radius: 30px; border: 1px solid #80808045">
          <v-col cols="12" sm="12" md="12">
            <p class="title ma-0" style="color: #f06a25">Configuración Dia</p>
          </v-col>

          <!-- <v-col cols="6" sm="6" md="6">
            <p class="title ma-0" style="color: #f06a25">Configuración Noche</p>
          </v-col> -->

          <v-col cols="6" sm="6" md="6">
            <p>Comienza</p>
            <v-text-field
              rounded
              outlined
              placeholder="08:00:00"
              v-model="newCategory.kmConfiguration.dayStarts"
            />
          </v-col>

          <!-- <v-col cols="6" sm="6" md="6">
            <p>Comienza</p>
            <v-text-field
              rounded
              outlined
              placeholder="08:00:00"
              v-model="newCategory.kmConfiguration.nightStarts"
            />
           
          </v-col> -->

          <v-col cols="6" sm="6" md="6">
            <p>Rango de Servicio KM</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.dayRange"
            />
          </v-col>

          <!-- <v-col cols="6" sm="6" md="6">
            <p>Rango de Servicio KM</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.nightRange"
            />
          </v-col> -->

          <!-- <v-col cols="6" sm="6" md="6">
            <p>Costo por Km recorrido</p>

            <v-text-field
              rounded
              @keypress="isNumber"
              outlined
              v-model.number="newCategory.kmConfiguration.nightCostKm"
            />
          </v-col> -->

          <v-col cols="6" sm="6" md="6">
            <p>Costo pickup</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.pickupDay"
            />
          </v-col>

          <!-- <v-col cols="6" sm="6" md="6">
            <p>Costo pickup</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.pickupNight"
            />
          </v-col> -->

          <v-col cols="6" sm="6" md="6">
            <p>Costo drop-off</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.dropoffDay"
            />
          </v-col>

          <v-col cols="6" sm="6" md="6">
            <p>Costo por Km recorrido</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.dayCostKm"
            />
          </v-col>

          <!-- <v-col cols="6" sm="6" md="6">
            <p>Costo drop-off</p>

            <v-text-field
              rounded
              outlined
              @keypress="isNumber"
              v-model.number="newCategory.kmConfiguration.dropoffNight"
            />
          </v-col> -->
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  props: ["newCategory"],
  name: "category-km",

  methods: {
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>