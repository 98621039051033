<template>
    <div>
        <v-snackbar absolute top :timeout="3000" v-model="snackbar">{{snackbarText}}</v-snackbar>
        
        <v-overlay class="loading-center" :value="saving">
            <lottie
                :options="defaultOptions"
                :height="300"
                :width="400"
                v-on:animCreated="handleAnimation"
            />
        </v-overlay>


        <v-card>
            <v-card-title  class="grid-close">
                    <span class="headline">Ordenar categorías</span>
                    <v-btn
                        style="right:10px; top:10px;"
                        icon
                        color="primary"
                        fab
                        absolute
                        @click="$emit('cancel')"
                        >
                        <v-icon dark>fa-times</v-icon>
                    </v-btn>

                     <!-- <v-autocomplete
                        v-model="citySelected"
                        :items="cities"
                        prepend-icon="fa-filter"
                        label="Filtrar por ciudad"
                        item-text="name"
                        item-value=".key"
                        aria-autocomplete="false"
                        class=" mt-10"
                        autocomplete="off"
                        hide-details
                        filled
                        rounded
                        :allow-overflow="false"
                        clearable
                    >
                    </v-autocomplete> -->
            </v-card-title>
        <v-card-text class="height-modal">

            <div v-if="localCategories.length == 0" class="mt-10" style="text-align:center">
                <span >Lista vacía 😔</span>
            </div>

            <draggable v-bind="dragOptions" @change="onChange" ghost-class="ghost" v-model="localCategories" @start="drag=true" @end="drag=false">
                <transition-group type="transition" name="flip-list">
                    <v-list-item
                        style="cursor: move"
                        class="list-group-item"
                        v-for="item in localCategories"
                        :key="item['.key']"
                    >

                        <v-list-item-content>
                            <v-list-item-title>{{item.name}}</v-list-item-title>
                        </v-list-item-content>

                        <v-list-item-icon>
                            <v-icon>fas fa-bars</v-icon>
                        </v-list-item-icon>
                    </v-list-item>
                </transition-group>
            </draggable>
        </v-card-text>

        <v-card-actions>
            <v-btn
                :disabled="!changed"
                color="primary"
                :loading="saving"
                block
                class="mb-5"
                height="50px"
                @click="updatePosition()"
            >
                Guardar
            </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>

import { db, fb } from "@/firebase";
import Lottie from "../../../components/Lottie"
import * as animationData from "../../../assets/ochoColor.json";
import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapActions, mapState } from 'vuex';
import draggable from 'vuedraggable'


export default {
  name: "Sort",
  props : ['city','categories'],
  components: {
    lottie: Lottie,
    draggable
  },
  data() {
   return {
        animationSpeed: 1.1,
        defaultOptions: {
            animationData: animationData.default,
            loop: true,
            autoplay: true
        },

        snackbar : false,
        snackbarText : '',
        saving : false,
        loading : true,
        sections : [],
        citySelected : null,
        localCategories : [],
        changed : false,
        drag : false
    }
  },
  computed : {
        ...mapState(['selectedBusiness']),

        dragOptions() {
            return {
                animation: 0,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            }
        },

        sortedArrayByPosition: function() {
            
            function compare(a, b) {  
               if ( (a.position && b.position) && (a.position < b.position))
                    return -1;
                if ( a.position && b.position && (a.position > b.position))
                    return 1;
                return 0;
            }

            return this.localCategories.sort(compare);
        }

  },

  watch : {
      citySelected ( data ) {
          this.localCategories = this.categories.filter(item => item.city == this.city )
          this.localCategories = Object.assign([], this.sortedArrayByPosition)
          this.changed = false
      }
  },
  async mounted(){
    this.citySelected = this.city
  },

  methods: {
   
    handleAnimation: function(anim) {
        this.anim = anim;
        anim.setSpeed(this.animationSpeed);
    },

    updatePosition() {
        this.saving = true

        this.localCategories.forEach((category, index) => {
            let position = index + 1
            db.collection("categories").doc(category['.key']).update({ 
                position : position
            })

            if ( this.localCategories.length == ( index + 1)) {
                // this.snackbarText = "Posiciones de los productos actualizadas correctamente."
                // this.snackbar = true
                this.changed = false
                this.saving = false
                this.$emit('success')
            }
        });
    },

    onChange() {
       this.changed = true 
    }
  }
};
</script>


<style scoped>
     .height-modal {
        height: 50vh;
        max-height: 50vh;
        min-height: 50vh;
        overflow-y: scroll;
    }

    .list-group-item {
       border-bottom: 1px solid rgba(128, 128, 128, 0.425);
    }

    .ghost {
        opacity: 0.5;
        background: #e0b19d;
    }

    .flip-list-move {
        transition: transform 0.5s;
    }
</style>
