var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[(!_vm.loading)?_c('a-transfer',{attrs:{"listStyle":{
          height: '400px!important',
          width: '40%',
          background: 'white',
        },"operationStyle":{
          width: '8%',
          textAlign: '-webkit-center',
        },"data-source":_vm.tags,"titles":['Todos las etiquetas', 'Etiquetas agregadas'],"target-keys":_vm.seletectedTags,"selected-keys":_vm.selectedKeys,"show-search":true,"render":(item) => item.title,"disabled":_vm.disabled,"filter-option":_vm.filterOption,"locale":{
          itemUnit: '',
          itemsUnit: '',
          notFoundContent: 'Lista vacía',
          searchPlaceholder: 'Buscar sección',
        }},on:{"search":_vm.handleSearch,"change":_vm.handleChange,"selectChange":_vm.handleSelectChange}}):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }