<template>
  <div>
    <v-snackbar absolute top :timeout="4000" v-model="snackbar">{{
      snackbarText
    }}</v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
        :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-card>
      <v-card-title class="grid-close">
        <span class="headline">
          {{ type == "edit" ? "Editar" : "Nueva" }} promoción de
          {{ category.name }}
        </span>
        <v-btn
          style="right: 10px; top: 10px"
          icon
          color="primary"
          fab
          absolute
          @click="$emit('cancel')"
        >
          <v-icon dark>fa-times</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-tabs v-model="tab" background-color="white" dark>
          <v-tab key="General">General</v-tab>
          <v-tab key="Comercios">Comercios</v-tab>
          <v-tab key="Produtos">Productos</v-tab>
          <v-tab key="Horario">Horario</v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item key="General">
            <v-card flat>
              <v-card-text style="min-height: 50vh">
                <!--skeleton -->
                <v-skeleton-loader
                  v-bind="attrs"
                  v-if="loading"
                  type="card-avatar, article, actions"
                ></v-skeleton-loader>
                <v-container v-if="!loading" fluid>
                  <v-row>
                    <v-col
                      style="
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        align-item: center;
                      "
                      cols="12"
                      sm="12"
                      md="12"
                    >
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <img
                            v-on="on"
                            v-show="imgPreview || promo.image.original"
                            @click.prevent="showFileChooser"
                            class="ad-img-promo"
                            :src="imgPreview || promo.image.original"
                          />
                          <div
                            v-on="on"
                            v-if="!imgPreview && !promo.image.original"
                            @click.prevent="showFileChooser"
                            class="img-container-promo"
                          >
                            <i style="font-size: 30px" class="fas fa-image"></i>
                            <span class="ml-2"
                              >Seleccionar imágen de la promoción</span
                            >
                          </div>
                        </template>
                        <span>Clic para cambiar la imágen</span>
                      </v-tooltip>
                      <input
                        class="upload"
                        ref="input"
                        type="file"
                        name="image"
                        accept="image/*"
                        @change="imageChanged"
                      />
                    </v-col>
                    <v-col cols="12" sm="12" md="12">
                      <p>Nombre de la promoción:</p>
                      <input
                        class="control-input"
                        type="text"
                        placeholder="Ingrese el nombre de la promoción"
                        v-model="promo.name"
                      />
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <p>Descripción:</p>
                      <textarea
                        maxlength="120"
                        placeholder="Descripción corta de la promoción"
                        v-model="promo.description"
                        class="control-input"
                        rows="1"
                      ></textarea>
                    </v-col>

                    <v-col cols="12" sm="12" md="12">
                      <p>Acción de Promo</p>
                      <v-autocomplete
                        v-model="promo.type"
                        :items="types"
                        placeholder="Seleccione tipo de promo"
                        outlined
                        item-value="value"
                        item-text="text"
                        rounded
                        class="mt-6"
                      />
                    </v-col>

                    <v-col cols="6" sm="6" md="6">
                      <p>Fecha de inicio:</p>
                      <v-menu
                        ref="menu"
                        v-model="menu"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <input
                            class="control-input"
                            v-on="on"
                            type="text"
                            placeholder="Fecha de inicio"
                            v-model="dates[0]"
                          />
                        </template>
                        <v-date-picker
                          no-title
                          scrollable
                          @input="menu = false"
                          v-model="dates[0]"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>

                    <v-col cols="6" sm="6" md="6">
                      <p>Fecha final:</p>
                      <v-menu
                        ref="menu"
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on }">
                          <input
                            class="control-input"
                            v-on="on"
                            type="text"
                            placeholder="Fecha final"
                            v-model="dates[1]"
                          />
                        </template>
                        <v-date-picker
                          no-title
                          scrollable
                          @input="menu2 = false"
                          v-model="dates[1]"
                        ></v-date-picker>
                      </v-menu>
                    </v-col>
                    <v-col cols="12" sm="6" md="6">
                      <v-autocomplete
                        v-model="promo.deliveryType"
                        :items="deliveryTypes"
                        placeholder="Tipo de delivery"
                        outlined
                        item-value="value"
                        item-text="text"
                        rounded
                        class="mt-6"
                      />
                    </v-col>

                    <v-col cols="12" sm="6" md="6">
                      <v-switch
                        v-model="promo.isViewMore"
                        class="mt-8"
                        label="Mostrar Botón Comercios"
                      ></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="Comercios">
            <v-card flat>
              <v-card-text style="min-height: 50vh">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-btn
                        @click="modalAddBusiness = true"
                        small
                        color="primary"
                      >
                        <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                        Agregar comercio
                      </v-btn>
                      <v-data-table
                        class="mt-5"
                        :headers="businessHeaders"
                        :items="promo.businessTarget"
                        :items-per-page="15"
                        :sort-desc="true"
                        item-key=".key"
                        :single-expand="true"
                        :footer-props="{
                          itemsPerPageOptions: [10, 25, 50, 100, 250],
                        }"
                      >
                        <template v-slot:[`item.options`]="{ item }">
                          <v-btn
                            @click="removeBusiness(item.business)"
                            small
                            class="ml-3 white--text"
                            color="red darken-4"
                            >Eliminar</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="!promo.isWholeMenu" key="Productos">
            <v-card flat>
              <v-card-text style="min-height: 50vh">
                <v-container>
                  <v-row>
                    <v-col cols="12" sm="12" md="12">
                      <v-btn
                        @click="addProductDialog = true"
                        small
                        color="primary"
                      >
                        <v-icon right dark class="mr-3">fas fa-plus</v-icon>
                        Agregar producto
                      </v-btn>
                      <v-data-table
                        class="mt-5"
                        :headers="headers"
                        :items="promo.target"
                        :items-per-page="15"
                        :sort-desc="true"
                        item-key=".key"
                        :single-expand="true"
                        :footer-props="{
                          itemsPerPageOptions: [10, 25, 50, 100, 250],
                        }"
                      >
                        <template v-slot:[`item.options`]="{ item }">
                          <v-btn
                            @click="removeProduct(item.product)"
                            small
                            class="ml-3 white--text"
                            color="red darken-4"
                            >Eliminar</v-btn
                          >
                        </template>
                      </v-data-table>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item key="Horario">
            <v-card flat>
              <v-card-text style="min-height: 50vh">
                <schedule :schedule="promo.schedule" />
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>

      <v-card-actions v-if="!loading">
        <v-container fluid>
          <v-divider></v-divider>
          <v-row class="pa-0 ma-0">
            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="start">
                <v-switch
                  v-model="promo.active"
                  label="Promoción activa"
                ></v-switch>
              </v-row>
            </v-col>

            <v-col cols="6" xs="6" sm="6" md="6">
              <v-row align="center" justify="end">
                <v-btn
                  v-if="type == 'new'"
                  @click="savePromo()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
                <v-btn
                  v-if="type == 'edit'"
                  @click="updatePromo()"
                  class="save-btn mt-4"
                  color="primary"
                  >Guardar</v-btn
                >
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-actions>
    </v-card>
    <add-remove-products
      @success="handleSuccess"
      :promo="promo"
      :category="category"
      @cancel="addProductDialog = false"
      v-if="addProductDialog"
    />
    <add-remove-business
      @success="handleSuccess2"
      :promo="promo"
      :category="category"
      @cancel="modalAddBusiness = false"
      v-if="modalAddBusiness"
    />
  </div>
</template>

<script>
import { db, fb } from "@/firebase";
import Lottie from "../../../../components/Lottie";
import * as animationData from "../../../../assets/ochoColor.json";
// import { Parser } from "json2csv";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import { mapState } from "vuex";

import newAdSkeleton from "../../skeleton/new-ad-skeleton";
import addRemoveProducts from "./add-remove-product";
import addRemoveBusiness from "./add-remove-business";

import { Button } from "ant-design-vue";
import { TimePicker } from "ant-design-vue";
import schedule from "../../../../components/Schedule";

export default {
  name: "new-promo",
  props: ["category", "position", "type", "promoToEdit"],
  components: {
    lottie: Lottie,
    newAdSkeleton,
    addRemoveProducts,
    addRemoveBusiness,
    aTimePicker: TimePicker,
    aButton: Button,
    schedule,
  },
  data() {
    return {
      animationSpeed: 1.1,
      defaultOptions: {
        animationData: animationData.default,
        loop: true,
        autoplay: true,
      },

      snackbar: false,
      snackbarText: "",
      addProductDialog: false,
      saving: false,
      modalAddBusiness: false,
      loading: true,
      menu: false,
      menu2: false,
      attrs: {
        class: "mb-6",
        boilerplate: true,
        elevation: 2,
      },
      types: [
        { text: "Comercios", value: "business" },
        { text: "Comercios Menú", value: "businessMenu" },
        { text: "Comercios Producto", value: "businessProduct" },
        { text: "Productos en Lista", value: "productList" },
        { text: "Productos", value: "product" },
      ],
      deliveryTypes: [
        { text: "Regular", value: "regular" },
        { text: "PickUp", value: "pickUp" },
      ],
      promo: {
        active: true,
        amount: 0,
        counter: 0,
        createdAt: "",
        createdBy: this.$store.state.user[".key"],
        type: "",
        isViewMore: false,
        dateEnd: "",
        dateStart: "",
        deleted: false,
        deletedAt: "",
        deletedBy: "",
        description: "",
        isCategoryShown: true,
        isChildOf: "",
        isFixed: true,
        isProductTarget: true,
        isWholeMenu: false,
        image: "",
        maximum: 0,
        name: "",
        position: 1,
        redeemed: 0,
        target: [],
        businessTarget: [],
        schedule: {
          monday: [],
          tuesday: [],
          wednesday: [],
          thursday: [],
          friday: [],
          saturday: [],
          sunday: [],
        },
      },

      headers: [
        {
          text: "Comercio",
          value: "businessName",
        },
        {
          text: "Producto",
          value: "productName",
        },
        {
          value: "options",
          align: "end",
        },
      ],

      businessHeaders: [
        {
          text: "Comercio",
          value: "businessName",
        },
        {
          value: "options",
          align: "end",
        },
      ],

      amountType: [
        { text: "Porcentual", value: false },
        { text: "Fijo", value: true },
      ],
      businesses: [],
      startsAt: "", //date
      endsAt: "", //date
      time: {
        startsAt: "08:00:00",
        endsAt: "19:00:00",
        startsActive: false,
        endsActive: false,
      },
      menuStartsAt: false,
      menuEndsAt: false,
      dates: ["", ""],
      imageFile: "",
      imgPreview: "",
      days: [
        "monday",
        "tuesday",
        "wednesday",
        "thursday",
        "friday",
        "saturday",
        "sunday",
      ],
      tab: null,
      items: [
        { tab: "General" },
        { tab: "Productos" },
        { tab: "Comercios" },
        { tab: "Horario" },
      ],
      roles: ["admin", "sudo", "AC", "EFCM"],
    };
  },

  async mounted() {
    if (this.roles.includes(this.user.type)) {
      let businesses = this.$binding(
        "businesses",
        db.collection("businesses").where("deleted", "==", false)
      ).then((businesses) => {
        this.businesses = this.businesses.map((item) => {
          return {
            text: item.shortName,
            value: item[".key"],
            logo: item.logo.original,
            isFather: item.isFather ? item.isFather : false,
          };
        });
        this.loading = false;
      });
    } else {
      let arrayCopy = Object.assign([], this.$store.state.user.businesses);
      arrayCopy.forEach((id, index) => {
        if (index % 10 == 0) {
          let array = arrayCopy.slice(index, index + 10);
          db.collection(`businesses`)
            .where(fb.firestore.FieldPath.documentId(), "in", array)
            .get()
            .then((businesses) => {
              businesses.forEach((business) => {
                this.businesses.push({
                  text: business.data().shortName,
                  value: business.id,
                  logo: business.data().logo.original,
                  isFather: business.data().isFather
                    ? business.data().isFather
                    : false,
                });
              });
              this.loading = false;
            });
        }
      });
    }

    if (this.type == "edit") {
      this.promo = Object.assign({}, this.promoToEdit);
      this.dates = [
        moment(new Date(this.promoToEdit.dateStart.seconds * 1000)).format(
          "YYYY-MM-DD"
        ),
        moment(new Date(this.promoToEdit.dateEnd.seconds * 1000)).format(
          "YYYY-MM-DD"
        ),
      ];
      this.time.startsAt = new Date(this.promoToEdit.dateStart.seconds * 1000)
        .toString()
        .substring(16, 24);
      this.time.endsAt = new Date(this.promoToEdit.dateEnd.seconds * 1000)
        .toString()
        .substring(16, 24);
    }
  },
  computed: {
    ...mapState(["user"]),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    generateTimestamp() {
      return new Promise((resolve, reject) => {
        let date = this.dates[0].split("-");
        let time = this.time.startsAt.split(":");
        this.promo.dateStart = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(time[0]),
            parseInt(time[1]),
            parseInt(time[2])
          )
        );
        date = this.dates[1].split("-");
        time = this.time.endsAt.split(":");
        this.promo.dateEnd = fb.firestore.Timestamp.fromDate(
          new Date(
            parseInt(date[0]),
            parseInt(date[1]) - 1,
            parseInt(date[2]),
            parseInt(time[0]),
            parseInt(time[1]),
            parseInt(time[2])
          )
        );
        resolve(true);
      });
    },

    getBusinessInformation() {
      let businessId;
      return new Promise((resolve, reject) => {
        businessId = this.promo.target[0].business;
        if (businessId) {
          db.collection("businesses")
            .doc(businessId)
            .get()
            .then((business) => {
              resolve({
                isChildOf: business.data().isChildOf
                  ? business.data().isChildOf
                  : business.id,
                categories: business.data().ochoCategories
                  ? business.data().ochoCategories
                  : [],
              });
            });
        }
      });
    },
  },

  methods: {
    moment,
    handleAnimation: function (anim) {
      this.anim = anim;
      anim.setSpeed(this.animationSpeed);
    },

    handleSuccess(newTarget) {
      this.promo.target = newTarget;
      this.addProductDialog = false;
    },

    handleSuccess2(newBusinessTarget) {
      this.promo.businessTarget = newBusinessTarget;
      this.modalAddBusiness = false;
    },

    validate() {
      if (!this.promo.name) {
        this.snackbarText = "Ingrese el nombre de la promoción";
        this.snackbar = true;
        return false;
      } else if (!this.promo.type) {
        this.snackbarText = "Debe elegir el tipo de promoción";
        this.snackbar = true;
        return false;
      } else if (!this.imageFile && this.type != "edit") {
        this.snackbarText = "Debe elegir una imágen.";
        this.snackbar = true;
        return false;
      } else if (!this.promo.deliveryType) {
        this.snackbarText = "Seleccione el tipo de delivery";
        this.snackbar = true;
        return false;
      } else {
        return true;
      }
    },

    async savePromo() {
      this.days.forEach((day) => {
        this.promo.schedule[day] = this.promo.schedule[day].map((item) => {
          return {
            closesAt: item.closesAt,
            opensAt: item.opensAt,
          };
        });
      });

      if (this.validate()) {
        let generateTimestamp = await this.generateTimestamp;
        this.promo.createdAt = new Date();
        if (this.promo.target.length > 0) {
          let businessInformation = await this.getBusinessInformation;
          this.promo.target.isChildOf = businessInformation.isChildOf;
        }

        this.promo.position = this.position + 1;
        this.saving = true;
        db.collection(`categories/${this.category[".key"]}/promos`)
          .add(this.promo)
          .then((ref) => {
            if (this.imageFile) {
              fb.app()
                .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-promos`)
                .ref()
                .child(
                  `${this.category[".key"]}/${ref.id}/file.${this.imageFile.name
                    .split(".")
                    .pop()}`
                )
                .put(this.imageFile)
                .then((res) => {
                  this.saving = false;
                  this.$emit("success");
                })
                .catch((err) => {
                  this.snackbarText =
                    "Ocurrió un error al subir la imágen, actualice desde la página de categorias";
                  this.snackbar = true;
                  this.saving = false;
                });
            } else {
              this.saving = false;
              this.$emit("success");
            }
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },

    async updatePromo() {
      this.days.forEach((day) => {
        this.promo.schedule[day] = this.promo.schedule[day].map((item) => {
          return {
            closesAt: item.closesAt,
            opensAt: item.opensAt,
          };
        });
      });

      if (this.validate()) {
        let generateTimestamp = await this.generateTimestamp;
        this.promo.modifiedAt = new Date();
        this.promo.modifiedBy = this.$store.state.user[".key"];

        if (this.promo.target.length > 0) {
          let businessInformation = await this.getBusinessInformation;
          this.promo.isChildOf = businessInformation.isChildOf;
        }

        let promoId = this.promo[".key"];
        delete this.promo[".key"];

        this.saving = true;
        db.collection(`categories/${this.category[".key"]}/promos`)
          .doc(promoId)
          .update(this.promo)
          .then((ref) => {
            if (this.imageFile) {
              fb.app()
                .storage(`gs://${process.env.VUE_APP_PROJECT_ID}-promos`)
                .ref()
                .child(
                  `${
                    this.category[".key"]
                  }/${promoId}/file.${this.imageFile.name.split(".").pop()}`
                )
                .put(this.imageFile)
                .then((res) => {
                  this.saving = false;
                  this.$emit("success");
                })
                .catch((err) => {
                  this.snackbarText =
                    "Ocurrió un error al subir la imágen, actualice desde la página de promociones";
                  this.snackbar = true;
                  this.saving = false;
                });
            } else {
              this.saving = false;
              this.$emit("success");
            }
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText =
              "Ocurrió un error inesperado, inténtelo nuevamente.";
            this.snackbar = true;
          });
      }
    },
    removeProduct(productId) {
      this.promo.target = this.promo.target.filter(
        (item) => item.product != productId
      );
    },

    removeBusiness(businessId) {
      this.promo.businessTarget = this.promo.businessTarget.filter(
        (item) => item.business != businessId
      );
    },

    handleClose() {
      this.time.startsActive = false;
      this.time.endsActive = false;
    },

    changeTime(time, index) {
      if (index == 1) {
        this.time.startsAt = moment(time).format("HH:mm:ss");
      } else {
        this.time.endsAt = moment(time).format("HH:mm:ss");
      }
    },

    showFileChooser() {
      this.$refs.input.click();
    },

    imageChanged(e) {
      if (!e.target.files[0]) return;

      let ref = this;
      this.imageFile = e.target.files[0];

      if (this.imageFile && this.imageFile.type.indexOf("image/") === -1) {
        console.log("tipo de archivo no permitido");
        this.imageFile = null;
      }

      var reader = new FileReader();
      reader.onload = function (e) {
        ref.imgPreview = e.target.result;
      };

      if (this.imageFile) reader.readAsDataURL(this.imageFile);
      else this.imgPreview = null;
    },
  },
};
</script>

<style>
.category-color {
  -webkit-appearance: none;
  width: 100%;
  margin-top: 10px;
  border-radius: 50px;
  height: 45px !important;
  cursor: pointer;
}

.category-color::-webkit-color-swatch {
  border-radius: 50px;
  padding: 0;
}
.category-color::-webkit-color-swatch-wrapper {
  border-radius: 50px;
  padding: 0;
}

.select-city {
  border-radius: 50px;
  padding: 0;
}

.control-input {
  border-color: rgba(117, 117, 117, 0.582);
}

.ad-img-promo {
  max-width: 100%;
  min-width: 100%;
  max-height: 250px;
  border-radius: 20px;
  cursor: pointer;
  object-fit: cover;
}

.img-container-promo {
  max-width: 100%;
  min-width: 100%;
  max-height: 200px;
  min-height: 200px;
  cursor: pointer;
  background-color: #92929221;
  border: 1px solid #80808062;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.time-picker .ant-time-picker-input {
  border: 1px solid rgba(0, 0, 0, 0.25) !important;
  border-radius: 10px !important;
  width: 100%;
  padding: 10px;
  margin-top: 10px;
  color: black;
  height: 44px;
}

.upload {
  display: none;
}

.v-tabs:not(.v-tabs--vertical) .v-tab {
  color: black !important;
  font-weight: bold;
}

.v-tab--active {
  border-bottom: 4px solid #f06a25 !important;
}
</style>