
<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" sm="12" md="12">
        <a-transfer
          v-if="!loading"
          :listStyle="{
            height: '400px!important',
            width: '40%',
            background: 'white',
          }"
          :operationStyle="{
            width: '8%',
            textAlign: '-webkit-center',
          }"
          :data-source="tags"
          :titles="['Todos las etiquetas', 'Etiquetas agregadas']"
          :target-keys="seletectedTags"
          :selected-keys="selectedKeys"
          :show-search="true"
          @search="handleSearch"
          :render="(item) => item.title"
          :disabled="disabled"
          :filter-option="filterOption"
          @change="handleChange"
          @selectChange="handleSelectChange"
          :locale="{
            itemUnit: '',
            itemsUnit: '',
            notFoundContent: 'Lista vacía',
            searchPlaceholder: 'Buscar sección',
          }"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { db, fb } from "@/firebase";

import transfer from "ant-design-vue/lib/transfer";

export default {
  props: ["newCategory", "country", "allTags"],
  name: "category-tags",
  components: {
    "a-transfer": transfer,
  },

  data() {
    return {
      tags: [],
      seletectedTags: [],
      selectedKeys: [],
      disabled: false,
      loading: true,
    };
  },

  methods: {
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.seletectedTags = nextTargetKeys;
      this.$emit("changed", nextTargetKeys);
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
  },

  async mounted() {
    // await this.$binding(
    //   "tags",
    //   db.collection("countries").doc(this.country).collection("tags")
    // );

    this.tags = this.allTags.map((item) => {
      return {
        key: item[".key"],
        title: item.name,
        categories: item.categories ? item.categories : [],
      };
    });

    this.seletectedTags = this.tags.filter(
      (tag) =>
        tag.categories && tag.categories.includes(this.newCategory[".key"])
    );

    this.seletectedTags = this.seletectedTags.map((i) => {
      return i.key;
    });

    this.$emit("changed", this.seletectedTags);

    this.loading = false;
  },
};
</script>

<style scoped>
</style>